import { html } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { input } from './base';
export const file = input(
	({ id, error, onChange, accept, multiple }) => html` <div
		class="input input-file"
	>
		<input
			class="file"
			type="file"
			name=${id}
			?multiple=${multiple}
			accept=${ifDefined(accept)}
			@change=${(e) => onChange(Array.from(e.target.files))}
		/>
		${error && html`<div class="failure">${error}</div>`}
	</div>`
);
