import { array } from '@neovici/cosmoz-utils/array';

const ERROR = Symbol('error'),

	runValidation = (validate, value, values, field) => {
		for (const check of array(validate)) {
			const error = check(value, values, field);
			if (error) {
				return error;
			}
		}
	},
	validateField = (field, values) => field.validate && runValidation(field.validate, values[field.id], values, field),
	validateFields = (fields, values) => {
		const errors = fields.flatMap(field => {
			const error = validateField(field, values);
			return error ? [[field.id, error]] : [];
		});
		return errors.length ? Object.fromEntries(errors) : undefined;
	},

	validateFieldsM = (fields, values) => {
		const validated = fields.map(field => ({ ...field, error: validateField(field, values) }));
		return {
			fields: validated,
			invalid: validated.some(({ error }) => !!error)
		};
	},
	validate = (fields, values) => values
		? validateFieldsM(fields, values)
		: {
			fields,
			invalid: true
		},

	validateForms = (fields, items) => {
		const forms = items.map((values, index) => ({ ...validate(fields, values), values, index }));
		return {
			forms,
			invalid: forms.some(({ invalid }) => invalid)
		};
	};

export {
	validateField,
	validateFields,
	validate,
	validateForms,
	runValidation,
	ERROR
};

export * from './rules';
