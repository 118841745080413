import {
	html, nothing
} from 'lit-html';
import { warning } from '../../cz-icon/cz-icon';
export const
	renderSuffix = suffix => suffix ? html`<span slot="suffix">${ suffix }</span>` : nothing,
	renderWarning = (msg, slot = 'suffix') => msg
		? html`<cz-icon
			style="color: var(--paper-amber-500); height: 22px; vertical-align: middle"
			slot=${ slot } width="22px" title=${ msg } .icon=${ warning }></cz-icon>`
		: nothing,
	renderContents = ({
		suffix, warning
	}) => [renderSuffix(suffix), renderWarning(warning)];
