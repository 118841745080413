const uuidv4 = () =>
		'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/gu, (c) => {
			const r = (Math.random() * 16) | 0, // eslint-disable-line no-bitwise
				v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
			return v.toString(16);
		}),
	/**
	 * Generate a random number between a minimum and maximum number.
	 * @param {number} min Minimum number.
	 * @param {number} max Maximum number.
	 * @returns {number} Random number between minimum and maximum number.
	 */
	random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min),
	/**
	 * Parse a date in YYYY-MM-DD format and return a date object
	 * @param {string} input Date in YYYY-MM-DD format.
	 * @returns {Date} Date
	 */
	parseDate = (input) => {
		const parts = input.match(/(\d+)/gu);
		return new Date(
			parseInt(parts[0], 10),
			parseInt(parts[1], 10) - 1,
			parseInt(parts[2], 10)
		);
	},
	/**
	 * Generates a random period.
	 * @param {string} start The start date to generate from.
	 * @param {string} end The end date to generate to.
	 * @returns {[string,string]} Dates.
	 */
	randomPeriod = (start, end) => {
		const startDate = parseDate(start),
			endDate = parseDate(end),
			timeDiff = Math.abs(endDate.getTime() - startDate.getTime()),
			diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)),
			randomDay = random(1, diffDays),
			randomDate = new Date(
				startDate.setTime(startDate.getTime() + randomDay * 86400000)
			),
			dueDate = new Date(endDate.setTime(randomDate.getTime() + 30 * 86400000));

		return [
			randomDate.getFullYear() +
				'-' +
				('0' + (randomDate.getMonth() + 1)).slice(-2) +
				'-' +
				('0' + randomDate.getDate()).slice(-2) +
				'T14:55:44.0+01:00',
			dueDate.getFullYear() +
				'-' +
				('0' + (dueDate.getMonth() + 1)).slice(-2) +
				'-' +
				('0' + dueDate.getDate()).slice(-2) +
				'T14:55:44.0+01:00',
		];
	},
	/**
	 * Get hash for a string.
	 * @param {string} str Input.
	 * @returns {number} Hash for the input.
	 */
	hash = (str) => {
		/* eslint-disable no-bitwise */

		let hash = 0;
		if (str.length === 0) {
			return hash;
		}
		for (let i = 0; i < str.length; i++) {
			const char = str.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash = hash & hash;
			hash = Math.abs(hash);
		}
		return hash;
	},
	toArray = (maybeArray) =>
		Array.isArray(maybeArray) ? maybeArray : [maybeArray];

export * from './fromXML';
export { hash, parseDate, random, randomPeriod, uuidv4, toArray };
