import { identity } from '@neovici/cosmoz-utils/function';
import { prop } from '@neovici/cosmoz-utils/object';
import { array } from '@neovici/cosmoz-utils/array';

export const invoke = (fn, ...args) =>
	typeof fn === 'function' ? fn(...args) : fn;
export const invokeValue = (valueFn, value, ...args) =>
	valueFn ? valueFn(value, ...args) : value;
export const initiate = (obj, fields) =>
	Object.fromEntries(
		fields.map(({ id, initiate = identity }) => [id, initiate(obj[id], obj)])
	);

/*
 * Transforms primitive values to an item from options
 *  and selection back to a primitive value on change.
 */
export const primeval = [
	(vs, _, { options, valueProperty }) =>
		options
			? array(vs).map(
					(v) =>
						(['string', 'number'].includes(typeof v) &&
							options.find((c) => prop(valueProperty)(c) === v)) ||
						v
			  )
			: vs,
	(v, _, { valueProperty }) =>
		v &&
		(Array.isArray(v) ? v.map(prop(valueProperty)) : prop(valueProperty)(v)),
];
