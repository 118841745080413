import { useCallback, useMemo, useState } from 'haunted';
import { useMeta } from '@neovici/cosmoz-utils/hooks/use-meta';
import { validateFields, ERROR } from './validation';
import { invoke } from '#components/cz-form/helpers.js';

const noRules = [],
	changed = (values, lastValues) =>
		!lastValues || values.some((value, i) => !Object.is(lastValues[i], value)),
	applyRules = ({ item, newItem, rules, index }) =>
		rules.reduce((currentItem, [computeFn, depsFn]) => {
			if (
				item &&
				depsFn &&
				!changed(depsFn(currentItem, index), depsFn(item, index))
			) {
				return currentItem;
			}
			return { ...currentItem, ...computeFn(currentItem, item, index) };
		}, newItem),
	applyRulesAll = (items, rules) =>
		items.map((newItem, index) => applyRules({ newItem, rules, index })),
	changes = (indexOrChanges, update) =>
		Array.isArray(indexOrChanges) ? indexOrChanges : [[indexOrChanges, update]],
	TOUCHED = Symbol('touched'),
	touch = (obj) => {
		obj[TOUCHED] = true;
		return obj;
	},
	emptyItems = touch([]),
	useItems = ({ initial, rules = noRules }) => {
		const _initial = useMemo(() => applyRulesAll(initial, rules), [initial]),
			[items, setItems] = useState(_initial);

		return {
			items,
			setItems,
			touched: !!items[TOUCHED],
			update: useCallback(
				(indexOrChanges, update) =>
					setItems((items) =>
						touch(
							changes(indexOrChanges ?? items.length, update).reduce(
								(acc, [index, update]) => [
									...acc.slice(0, index),
									touch(
										applyRules({
											item: acc[index],
											newItem: { ...acc[index], ...update },
											rules,
											index,
										})
									),
									...acc.slice(index + 1),
								],
								items
							)
						)
					),
				rules
			),
			updateAll: useCallback(
				(updateOrFn) =>
					setItems((items) =>
						items.map((item, index) =>
							touch(
								applyRules({
									item,
									newItem: { ...item, ...invoke(updateOrFn, item) },
									rules,
									index,
								})
							)
						)
					),
				[rules]
			),
			remove: useCallback(
				(index) =>
					setItems((items) =>
						touch(
							applyRulesAll(
								[...items.slice(0, index), ...items.slice(index + 1)],
								rules
							)
						)
					),
				[rules]
			),
			reset: useCallback(() => setItems(_initial), [_initial]),
			clear: useCallback(() => setItems(emptyItems), []),
			load: useCallback(
				(items, rulesOverride) =>
					setItems(applyRulesAll(items, rulesOverride || rules)),
				rules
			),
		};
	},
	useValidatedItems = ({ initial, rules = noRules, fields }) => {
		const meta = useMeta({ fields }),
			validation = useMemo(
				() => [(item) => ({ [ERROR]: validateFields(meta.fields, item) })],
				[meta]
			),
			{ items, ...rest } = useItems({
				initial,
				rules: useMemo(() => [...rules, validation], [rules, validation]),
			}),
			invalid = useMemo(() => items.some((i) => i[ERROR]), [items]);

		return {
			...rest,
			items,
			invalid,
		};
	};

export { TOUCHED, useItems, useValidatedItems };
