import renderStatus, { style as statusStyle } from '#cz/render/status/index.js';
import { html } from 'lit-html';
import { apiUrl, jsonPost } from '../../../lib/api';
import { timeout$ } from '@neovici/cosmoz-utils/promise';

const renderStatusCell = (column, { item }) => [
		html`<style>
			${statusStyle}
		</style>`,
		renderStatus(item?.status),
	],
	renderLedgerCell = (column, { item }) =>
		html`<cz-link
			view="purchase/petty-cash/ledgers/view"
			param-id=${item.ledger?.id}
			>${item.ledger?.number}</cz-link
		>`,
	renderReimbursementRequestCell = (column, { item }) =>
		html`<cz-link
			view="purchase/petty-cash/reimbursement-requests/view"
			param-id=${item.reimbursementRequest?.id}
			>${item.reimbursementRequest?.number}</cz-link
		>`,
	renderDetailsLink =
		(view) =>
		(column, { item }) =>
			html`<cz-link view=${view} param-id=${item.id}>${item.number}</cz-link>`,
	createShadowBatchForUploads = () =>
		jsonPost(
			apiUrl('api/v2/billing-items-batch', {
				pathLocator: '1.18.19.2685.70660',
				batchIdentifier:
					'CSV_+32553_202206241' +
					Math.round(Math.random() * 10000) +
					'_cristian.ncl%40gmail.com',
				fileName: 'manual_entry',
				disableDiscounts: true,
				disableFee: true,
			}),
			[
				{
					quantity: 1,
					quantityUnit: 'ST',
					unitPrice: 1,
					currency: 'SEK',
					ourReference: 'Test',
					customerNumber: '34027',
					articleNumber: '500',
					accountingAccount: '3901',
				},
			]
		).then((result) => timeout$(3000).then(() => result));

export {
	renderStatusCell,
	renderLedgerCell,
	renderReimbursementRequestCell,
	renderDetailsLink,
	createShadowBatchForUploads,
};
