import { transform, merge } from '@neovici/cosmoz-utils/object';

const normalizeValue = (v) => v.toLowerCase().trim(),
	importColumns = transform(
		{
			amount: ['Importe en Moneda del Docto.'],
			account: ['Cuenta de Mayor'],
			division: [],
			vatCode: ['Indicador de IVA'],
			freeText: ['Texto de Cabecera Documento'],
			costCenter: ['Centro de costos'],
			invoiceNumber: ['Número de Doc. De Referencia '],
		},
		(e) => e.map(([key, value]) => [key, [key, ...value].map(normalizeValue)])
	),
	getNormalizeRow = (rows, account) => {
		const row = rows.find((c) => c.account?.toString() === account?.toString());
		return row ? { row, isAccountingRow: true } : { row: rows[0] };
	};

export const normalizeRows = (
		accRows,
		rows,
		dimensions = ['division', 'vatCode', 'freeText']
	) => {
		const firstRow = accRows.find((r) => r.rowType === 'Debit');
		if (!firstRow) {
			return [rows];
		}
		const { row: firstRRow, isAccountingRow } = getNormalizeRow(
			rows,
			firstRow.dimensions?.account
		);
		if (!firstRRow) {
			return [rows];
		}
		const rrows = isAccountingRow ? rows.filter((r) => r !== firstRRow) : rows,
			changedColumns = dimensions.flatMap((d) => {
				const oldValue = firstRow.dimensions[d];
				if (oldValue) {
					return [];
				}
				const value = firstRRow[d];
				return value ? [{ type: d, oldValue, newValue: value }] : [];
			}),
			amount =
				isAccountingRow && firstRRow.amount * (firstRow.amount < 0 ? -1 : 1);

		if (
			changedColumns.length < 1 &&
			isAccountingRow &&
			amount === firstRow.amount
		) {
			return [rrows];
		}
		const changes = Object.fromEntries(
			changedColumns.map(({ type, newValue }) => [type, newValue])
		);

		return [
			rrows,
			[
				{
					row: merge(firstRow, {
						dimensions: changes,
						...(amount ? { amount } : {}),
					}),
					rowIndex: accRows.indexOf(firstRow),
					changedColumns,
				},
			],
		];
	},
	importAccounting = async (file, columns = importColumns) => {
		await import('exceljs/dist/exceljs.min.js');
		const workbook = new window.ExcelJS.Workbook();
		await workbook.xlsx.load(await file.arrayBuffer());
		let headers;
		const rows = [];
		workbook.getWorksheet().eachRow((row) => {
			if (headers == null) {
				headers = row.values;
				return;
			}
			rows.push(
				Object.fromEntries(
					row.values.flatMap((value, i) => {
						const rawHeader = headers[i];
						if (!rawHeader) {
							return [];
						}
						const key = Object.entries(columns).find(([, value]) =>
							value.includes(normalizeValue(rawHeader))
						)?.[0];
						return key ? [[key, value]] : [];
					})
				)
			);
		});
		return rows;
	};
