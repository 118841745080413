import { invoke, invokeValue } from '../helpers';

export const input = render => ({ field, value, values, onChange, ...thru }) => render({
	...field,
	...thru,
	values,
	label: invoke(field.label, value, values, field),
	disabled: invoke(field.disabled, value, values, field),
	warning: invoke(field.warning, value, values, field),
	suffix: invoke(field.suffix, value, values, field),
	value: invokeValue(field.value?.[0], value, values, field),
	onChange: value => (field.onChange ?? ((update, id, value, values) => {
		if (values != null && Object.is(values[id], value)) {
			return;
		}
		update({ [id]: value });
	}))(onChange, field.id, invokeValue(field.value?.[1], value, values, field), values)
});

