import { html } from 'lit-html';
import '@neovici/cosmoz-input';
import '@neovici/cosmoz-input/textarea';
import { input } from './base';
import { renderContents } from './render';
import { ifDefined } from 'lit-html/directives/if-defined.js';

const patternNumber = /[0-9.,e-]/u,
	parseNumber = (value) => {
		const num = parseFloat(value);
		return isNaN(num) ? undefined : num;
	},
	renderCommon = ({
		id,
		type = 'text',
		label,
		placeholder,
		noLabelFloat,
		error,
		suffix,
		warning,
		allowedPattern,
		step,
		disabled,
		onInput,
		value,
		maxlength,
	}) => html`<cosmoz-input
		class="input input-common input-${type}"
		name=${id}
		type=${type}
		?disabled=${disabled}
		?no-label-float=${noLabelFloat}
		?invalid=${!!error}
		.placeholder=${placeholder}
		.errorMessage=${error}
		.allowedPattern=${allowedPattern}
		.step=${step}
		.label=${label}
		.value=${value}
		title=${ifDefined(error)}
		maxlength=${ifDefined(maxlength)}
		@input=${onInput}
		>${renderContents({ suffix, warning })}</cosmoz-input
	>`,
	text = input(({ onChange, ...props }) =>
		renderCommon({
			...props,
			onInput: (e) => onChange(e.target.value),
		})
	),
	number = input(({ onChange, allowedPattern = patternNumber, ...props }) =>
		renderCommon({
			...props,
			type: 'number',
			allowedPattern,
			onInput: (e) => onChange(parseNumber(e.target.value)),
		})
	),
	date = input(({ onChange, ...props }) =>
		renderCommon({
			...props,
			type: 'date',
			onInput: (e) => onChange(e.target.value),
		})
	),
	textarea = input(
		({
			id,
			label,
			placeholder,
			noLabelFloat,
			error,
			suffix,
			warning,
			disabled,
			onChange,
			value,
			maxRows,
			rows,
			maxlength,
		}) => html`<cosmoz-textarea
			class="input input-textarea"
			name=${id}
			?disabled=${disabled}
			?no-label-float=${noLabelFloat}
			?invalid=${!!error}
			.placeholder=${placeholder}
			.errorMessage=${error}
			.label=${label}
			.value=${value}
			.rows=${rows}
			.maxRows=${maxRows}
			maxlength=${ifDefined(maxlength)}
			@input=${(e) => onChange(e.target.value)}
			>${renderContents({ suffix, warning })}</cosmoz-textarea
		>`
	);

export { text, textarea, number, date };
