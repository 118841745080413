import { html } from 'lit-html';
import { live } from 'lit-html/directives/live.js';
import '@neovici/cosmoz-autocomplete';
import { input } from './base.js';
import { renderContents } from './render';

export const autocomplete = input(({
	id,
	label,
	noLabelFloat,
	error,
	warning,
	suffix,
	disabled,
	onChange,
	options,
	limit,
	textProperty,
	valueProperty,
	value,
	values
}) => html`<cosmoz-autocomplete
	class="input input-autocomplete"
	?data-warning=${ !!warning }
	name=${ id }
	?disabled=${ disabled }
	?no-label-float=${ noLabelFloat }
	?invalid=${ !!error }
	.errorMessage=${ error }
	.label=${ label }
	.value=${ live(value) }
	.source=${ typeof options === 'function' ? info => options({ ...info, value, values }) : options }
	.textProperty=${ textProperty } .valueProperty=${ valueProperty }
	.limit=${ limit }
	.onChange=${ options => onChange(limit === 1 ? options?.[0] : options, values) }
>${ renderContents({ suffix, warning }) }</cosmoz-autocomplete>`);
