import { _ } from '@neovici/cosmoz-i18next';

import { luhn as isLuhn } from '../../cz-utils/luhn.js';
import { gln as isGln } from '../../cz-utils/gln.js';
import { ensureDate } from '@neovici/cosmoz-utils/date';

export const missing = (value) =>
		value == null || value === '' || Number.isNaN(value),
	required = (value) => missing(value) && _('Required'),
	notAnOption =
		(options, key = 'value') =>
		(value) =>
			!missing(value) &&
			options != null &&
			!options.some((option) => option[key] === value) &&
			_('Option not among possible values'),
	tooShort = (length) => (value) =>
		!missing(value) &&
		value.length < length &&
		_('Must have at least {0} characters', length),
	tooSmall = (min) => (value) =>
		!missing(value) &&
		value < min &&
		_('Value must be greater or equal to {0}', min),
	tooBig = (max) => (value) =>
		!missing(value) &&
		!missing(max) &&
		value > max &&
		_('Value must be less or equal to {0}', max),
	doesNotMatchFormat =
		(regexp, message = 'Does not match format: ' + regexp) =>
		(value) =>
			!missing(value) && !value.match(regexp) && message,
	luhn = (value) =>
		!missing(value) && !isLuhn(value) && _('Not a valid identification number'),
	gln = (value) =>
		!missing(value) && !isGln(value) && _('Not a valid identification number'),
	onlyDigits = (value) =>
		!missing(value) && !/^[0-9]+$/u.test(value) && _('Only digits allowed'),
	exactLength = (length) => (value) =>
		!missing(value) &&
		value.length !== length &&
		_('Must have exactly {0} characters', length),
	hint = (field) => ({
		value: [
			(value, values) => {
				if (value != null) {
					return value;
				}
				const hintValue = values[field];
				return hintValue != null ? { label: hintValue } : undefined;
			},
		],
		onChange: (update, id, value) =>
			update({
				[id]: value,
				[field]: undefined,
			}),
		validate: (value, values) => {
			const req = required(value);
			if (!req) {
				return;
			}
			const hintValue = values[field];
			return hintValue == null ? req : _('Option not among possible values');
		},
	}),
	afterStartDate = (startDateField) => (value, values) => {
		const end = ensureDate(value),
			start = ensureDate(values[startDateField]);
		return (
			end &&
			start &&
			end.getTime() <= start.getTime() &&
			_('End date must be after start date')
		);
	},
	minDate = (date) => (value) => {
		const dateValue = ensureDate(value),
			minDateValue = ensureDate(date);
		return (
			dateValue &&
			minDateValue &&
			dateValue.getTime() <= minDateValue.getTime() &&
			_('Date must be bigger than {0}', date)
		);
	},
	maxDate = (date) => (value) => {
		const dateValue = ensureDate(value),
			maxDateValue = ensureDate(date);
		return (
			dateValue &&
			maxDateValue &&
			maxDateValue.getTime() <= dateValue.getTime() &&
			_('Date must be lower than {0}', date)
		);
	};
