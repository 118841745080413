import {
	useCallback,
	useEffect,
	useRef,
	useState
} from 'haunted';
import { json } from './fetch';

export { json, jsonPost } from './fetch';
export { multipart } from './multipart';

export const
	postJSON = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		}
	},

	toURLSearchParams = obj => {
		const entries = Object.entries(obj),
			simpleParams = Object.fromEntries(entries.filter(([, value]) => !Array.isArray(value) && value !== undefined)),
			allParams = new URLSearchParams(simpleParams);

		// handle arrays
		entries.filter(([, value]) => Array.isArray(value))
			.forEach(([key, values]) => values.forEach(value => allParams.append(key, value)));

		return allParams;
	},

	apiUrl = (api, params = {}, deps = [], mockup = false) => {
	// bail if any required dependencies are undefined
		if (deps.some(dep => dep == null)) {
			return;
		}

		const baseUrl = mockup ? `${ window.location.origin }/tools/assets/mockup/` : window.cz.options.backendBaseUri, // TODO: get backendBaseUri via useContext
			url = new URL(mockup ? `${ api }.json` : api, baseUrl);
		url.search = toURLSearchParams({ ...Object.fromEntries(url.searchParams), ...params });
		return `${ url }`;
	},

	noOpts = {},
	useApi = (url, opts = noOpts) => {
		const
			{ fetch = json } = opts,
			[state, setState] = useState({ fetching: false });

		return {
			...state,

			fetch: useCallback(overrides => {
				setState({
					fetching: true
				});

				return fetch(url, {
					...opts,
					...overrides
				})
					.then(data => setState({
						fetching: false,
						data
					}))
					.catch(error => setState({
						fetching: false,
						error
					}));
			}, [url, opts])
		};
	},

	useApiEffect = (url, opts) => {
		const api = useApi(url, opts),
			prevFetch = useRef();

		useEffect(() => {
			if (url == null) {
				return;
			}

			const ac = new AbortController();

			// initiate the fetch only after the previous fetch has finished processing,
			// including abort caused by effect destructor
			Promise.resolve(prevFetch.current)
				.then(() => {
					prevFetch.current = api.fetch({ signal: ac.signal });
				});

			return () => ac.abort();
		}, [api.fetch, url]);

		return api;
	},

	useOnSuccess = ({
		fetching, data, error
	}, onSuccess) => useEffect(
		() => onSuccess != null && !fetching && data != null && error == null && onSuccess(data),
		[fetching, data, error]
	);
