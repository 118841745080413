import { html } from 'lit-html';
import { tagged as css } from '@neovici/cosmoz-utils';

export const style = css`
	.status {
		margin-left: auto;
		font-size: 12px;
		border-radius: 12px;
		padding: 2px 12px;
		text-transform: uppercase;
		background: rgba(214, 216, 224, 0.5);
		color: #6b6d7a;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
		box-sizing: border-box;
		display: inline-block;
	}

	[data-severity='error'] {
		color: #f04471;
		background: #ffdfec;
	}

	[data-severity='ok'] {
		background: rgba(119, 234, 138, 0.5);
		color: #1a7d0a;
	}

	[data-severity='warning'],
	[data-status='duplicate'] {
		color: #e36000;
		background: rgba(255, 221, 181, 0.5);
	}
`;

export default (status) =>
	status &&
	html`<div
		class="status"
		data-severity=${status.severity}
		data-status=${status.name}
		title=${status.description}
	>
		${status.description}
	</div>`;
