class RequestError extends Error {
	response: Response;
	constructor(message: string, response: Response) {
		super(message); // (1)
		this.name = 'RequestError'; // (2)
		this.response = response;
	}
}

interface CzWindow extends Window {
	cz: {
		state: {
			currentLocationPath: string;
		};
	};
}

const notice = (data: unknown) => {
	if (data && typeof data === 'object') {
		const obj = data as { [key: string]: string | undefined },
			message = obj.message || obj.Message || obj.friendlyMessage;
		if (message) {
			return message;
		}
	}
	return 'Unknown error';
};

export const cplHeaders = () => {
		const cpl = (window as unknown as CzWindow).cz?.state?.currentLocationPath;
		return cpl ? { 'cz-path-locator': cpl } : undefined;
	},
	fetch = (
		url: string,
		opts: RequestInit & { noCpl?: boolean }
	) =>
		window.fetch(url, {
			mode: 'cors',
			credentials: 'include',
			...opts,
			headers: {
				...opts?.headers,
				...(opts?.noCpl ? {} : cplHeaders()),
			},
		}),
	handleJSON = (res: Response) => {
		if (res.status === 204) {
			return '';
		}
		const json = res.json();
		return res.ok
			? json.catch(() => '')
			: json.then((data) =>
					Promise.reject(new RequestError(notice(data), res))
			  );
	},
	json = (
		url: string,
		{
			fetch: _fetch = fetch,
			...opts
		}: RequestInit & { fetch?: typeof fetch } = {}
	) => _fetch(url, opts).then(handleJSON),
	jsonPost = (
		url: string,
		body: object | BodyInit,
		opts?: Omit<RequestInit, 'body'>
	) =>
		json(url, {
			method: 'POST',
			...(body ? { body: JSON.stringify(body) } : {}),
			...opts,
			headers: {
				'Content-Type': 'application/json',
				...opts?.headers,
			},
		});
