/* eslint-disable max-lines */
import '@polymer/iron-flex-layout/iron-flex-layout';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import { tagged as css } from '@neovici/cosmoz-utils';

const documentContainer = document.createElement('template');

export const dataNavButtonsStyle = `
		cosmoz-data-nav paper-icon-button,
		.button-nav {
			background-color: #fff;
			border-radius: 500px;
			border: solid 1px #a4abae;
			font-size: 1.08em;
			letter-spacing: -0.01em;
			padding: 0 10px;
			text-transform: none;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: #343434;
			font-weight: normal;
			margin: 0 0.29em;
		}

		.button-nav[disabled] {
			opacity: 0.5;
		}
	`,
	fit = `
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	`,
	fitClass = `
		.fit { ${fit} }
	`,
	flexClass = `
		.flex {
			flex: 1;
			flex-basis: 0.000000001px;
		}
	`,
	toggleStyles = css`
		.toggle {
			appearance: none;
			width: 35px;
			height: 18px;
			display: inline-block;
			position: relative;
			border-radius: 18px;
			overflow: hidden;
			outline: none;
			border: none;
			cursor: pointer;
			background: var(--cz-toggle-color);
			transition: background-color ease 0.25s;
			margin: 0;
		}
		.toggle::before {
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
			width: 14px;
			height: 14px;
			background: var(--cz-toggle-thumb-color);
			left: 2px;
			top: 2px;
			border-radius: 50%;
			transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.25s;
		}
		.toggle:checked {
			background: var(--cz-toggle-checked-color);
		}
		.toggle:checked::before {
			left: 19px;
		}
		.toggle + label {
			padding-left: 16px;
			font-size: 14px;
			line-height: 18px;
			cursor: pointer;
		}
		.toggle[disabled] {
			opacity: 0.6;
		}
	`,
	buttonStyles = css`
		.button {
			font-family: inherit;
		}
		.button:not([slot='bottom-bar-menu']),
		.file::-webkit-file-upload-button,
		.file::file-selector-button {
			background: var(--cosmoz-button-bg-color, #101010);
			color: var(--cosmoz-button-color, #fff);
			box-sizing: border-box;
			cursor: pointer;
			outline: none;
			flex: none;
			border: none;
			padding: 0 18px;
			border-radius: 6px;
			min-height: 40px;
			min-width: 78px;
			font-family: inherit;
			font-size: 14px;
			font-weight: 500;
			line-height: 40px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.button:not([slot='bottom-bar-menu']):hover,
		.button:not([slot='bottom-bar-menu']):active,
		.file::-webkit-file-upload-button:hover,
		.file::file-selector-button:hover {
			background: var(--cosmoz-button-hover-bg-color, #3a3f44);
		}
		.button[disabled],
		.button:disabled {
			opacity: var(--cosmoz-button-disabled-opacity, 0.15);
			pointer-events: none;
		}
		a.button {
			text-decoration: none;
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
			padding: 8px 8px 8px 24px;
		}
		.buttons .button {
			margin: 0 4px;
		}
		.button-ricon {
			border: none;
			border-radius: 50%;
			padding: 8px;
			display: inline-flex;
			background: transparent;
			cursor: pointer;
			transition: background 0.15s ease;
		}
		.button-ricon:active {
			background: rgb(56, 62, 74, 0.15);
		}
	`,
	tabnav = css`
		.tabnav::part(tabs) {
			--cosmoz-tabs-bg-color: var(--cz-bg-color);
			box-shadow: none;
			position: relative;
		}
		.tabnav::part(tab) {
			flex: 0.01 1 105px;
			background: inherit;
		}
		.tabnav::part(last-tab) {
			margin-right: auto;
		}
		.tabnav::part(first-tab) {
			margin-left: auto;
		}
		.tabnav-title {
			font-size: 17px;
			font-weight: 600;
			line-height: 25px;
			color: var(--cz-text-secondary-color);
			margin-left: 18px;
			white-space: nowrap;
		}
		@media (min-width: 1060px) {
			.tabnav-title {
				position: absolute;
			}
		}
		@media (max-width: 400px) {
			.tabnav-title {
				font-size: 14px;
				margin-left: 12px;
			}
			.tabnav::part(tab) {
				flex-basis: 50px;
			}
			.tabnav::part(last-tab) {
				margin-right: initial;
			}
		}
	`,
	_sharedStyles = `
		a {
			color: var(--primary-link-color, inherit);
		}
		.page-header {
			padding: 0 10px;
			background-color: var(--cosmoz-page-header-background-color, --light-primary-color, #f5f5f5);
			min-height: 70px;
			margin-bottom: 3px; /* make space for shadow */
			display: flex;
			flex-direction: row;
			align-items: center;
			overflow-x: hidden;
		}
		.page-header cz-letterball {
			height: 42px;
			min-height: 42px;
			width: 42px;
			min-width: 42px;
			margin-right: 10px;
		}
		.page-header > div:first-of-type {
			display: flex;
			flex-direction: column;
			flex: 1;
			flex-basis: 0.000000001px;
			overflow-x: hidden;
		}
		.page-header > div:first-of-type > div {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
		.page-header > div:first-of-type > div:first-of-type {
			margin: 0;
		}
		.page-header > div:first-of-type > div {
			margin-top: 5px;
			margin-bottom: 5px;
		}
		.page-header > div:first-of-type > div > div {
			color: #fff;
			font-weight: 300;
			border-radius: 12px;
			padding: 2px 12px;
			background-color: #bbc2c4;
		}
		.page-header > div:first-of-type > div > div.error {
			background-color: #FC5C5B;
		}
		.page-header > div:first-of-type > div > div.ok {
			background-color: #18A82B;
		}
		.page-header h3 {
			margin: 0 0 5px 0;
			font-weight: 300;
			font-size: 1.24em;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;
			text-align: right;
		}

		.row {
			display: flex;
			flex-direction: row;
			color: #222;
		}

		.row > div:first-child {
			margin-left: 0;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			color: #777;
		}

		abbr {
			border-bottom: 1px dotted;
			cursor: help;
		}

		.cutoff {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.no-text-select {
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		.text-select {
			-webkit-touch-callout: text;
			-webkit-user-select: text;
			-khtml-user-select: text;
			-moz-user-select: text;
			-ms-user-select: text;
			user-select: text;
		}

		paper-button:hover {
			/*background-color: var(--accent-color);*/
		}

		paper-button > iron-icon {
			margin-right: 0.2em;
			margin-left: -0.3em;
			min-width: 18px;
			min-height: 18px;
			height: 18px;
			width: 18px;
		}

		/**
		 * Workaround for https://github.com/PolymerElements/paper-dropdown-menu/issues/154
		 * FIXME: Should be removed when issue is solved.
		 */
		paper-dropdown-menu {
			--paper-menu-button: {
				width: 100%;
			};
		}

		iron-form .buttons, cosmoz-dialog .buttons {
			color: #343434;
		}

		cosmoz-dialog .buttons {
			/**
			 * Workaround for missing --paper-dialog-buttons mixin
			 */
			@apply --cosmoz-treenode-button-view-dialog-container;
		}

		.bordered {
			padding: 10px;
			border-bottom: solid 1px #e2e2e2;
		}

		div.history-timegroup {
			font-weight: 400;
			margin-top: 25px;
			margin-bottom: 25px;
			font-size: 18px;
		}
		div.history-timegroup:first-letter {
			text-transform: uppercase;
		}

		div.timegroup-item-comment {
			color: #1eacf4;
			font-weight: 300;
			font-style: italic;
		}

		div.float-right {
			float: right;
		}

		.extendedInformation {
			max-height: 0px;
			z-index: 1;
			overflow: hidden;
			padding-left: 15px;
		}

		.extendedInformation.anim {
			transition: max-height 0.2s;
		}

		.extendedInformation > div > div {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.round-button {
			outline: none;
			-webkit-user-select: none;
			user-select: none;
			cursor: pointer;
			z-index: 0;
			box-sizing: border-box;
			width: 56px;
			height: 56px;
			color: #343434;
			background-color: #fff;
			border-radius: 50%;
			padding: 14px;
			border: 2px solid #f5f5f5;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
		}

		.event-description {
			font-weight: 400;
			font-size: 16px;
		}

		.event-comment {
			color: #636363;
			font-weight: 300;
			font-style: italic;
			font-size: 0.9em;
		}

		paper-button iron-icon {
			display :none !important;
		}

		/* Styles for buttons in cosmoz-toolbar */
		paper-button.cosmoz-bottom-bar-toolbar > iron-icon {
			display: none;
		}

		paper-button.cosmoz-bottom-bar-menu > iron-icon {
			display: none;
		}

		cosmoz-omnitable {
			--item-row-min-height: 39px;
		}

		${dataNavButtonsStyle}

		cosmoz-treenode-button-view {
			--paper-listbox-background-color: #ffffff;
		}

		.inherited-value::after {
			content: ' *';
		}

		ul.comma-list {
			display: inline;
			list-style: none;
			padding: 0;
			margin: 0;
		}
		ul.comma-list li,
		ul.comma-list li a {
			display: inline;
		}
		ul.comma-list li:after {
			content: ", ";
		}
		ul.comma-list li:last-of-type:after {
			content: "";
		}
		${tabnav}
	`,
	sharedStyles = window.ShadyCSS.ApplyShim.transformCssText(_sharedStyles);

documentContainer.innerHTML = `
<dom-module id="shared-styles">
	<template>
		<style is="custom-style" include="iron-flex iron-flex-reverse iron-flex-alignment iron-flex-factors iron-positioning">
			${sharedStyles}
		</style>
	</template>
</dom-module>`;

document.head.appendChild(documentContainer.content);
