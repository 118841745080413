/* eslint-disable max-lines */

import { _ } from '@neovici/cosmoz-i18next';
import { pad } from '@neovici/cosmoz-utils/date';
import { transform } from '@neovici/cosmoz-utils/object';
import { useEffect, useState } from 'haunted';
import { importAccounting } from '../../../cz-components/cz-accounting/import';
import {
	autocomplete,
	file,
	number,
	required,
} from '../../../cz-components/cz-form';
import { uuidv4 } from '../../../entities/utils';
import { createShadowBatchForUploads } from './utils';

const demodata = () => {
		try {
			return JSON.parse(localStorage.pettycashdemodata);
		} catch (e) {
			return undefined;
		}
	},
	assigneeOptions = [
		{
			id: '36d99646-e416-4f5c-a4ea-aeb600d8ce3b',
			fullName: 'Martin Bolfeta (Xpertal Operator)',
		},
		{
			id: '215a3794-cc1e-4fed-a72c-aeb600d7ca7b',
			fullName: 'Mattias Berggren (Business Unit Manager)',
		},
	],
	normalizeValue = (v) => v.toLowerCase().trim(),
	importColumns = transform(
		{
			requestNumber: ['Número de solicitud'],
			paymentMethod: ['Método de pago'],
			amount: ['Monto'],
			account: ['Cuenta'],
			division: [],
			vatCode: ['Indicador de IVA'],
			freeText: ['Texto libre'],
			costCenter: ['Centro de costos'],
		},
		(e) => e.map(([key, value]) => [key, [key, ...value].map(normalizeValue)])
	),
	initialData = {
		acrs: [
			{
				id: '1',
				amount: 1000,
				ledger: {
					id: '1',
					number: 'PC01001',
				},
				// Creditor.
				number: 'AC10020207',
				currency: 'MXN',
				indicator: 'IB',
				parentPathLocator: '1.271560.1',
				createDate: '2022-06-01T21:19:02.306785Z',
				status: {
					severity: 'error',
					name: 'rejected',
					description: 'Rejected',
				},
				events: [
					{
						cosmozItemId: '73b5219d-b8a1-4e17-be59-aea7015f4c18',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-06-09T21:19:02.6461284Z',
						eventType: 8,
						eventCode: 3004,
						eventDescription: {
							text: 'Advance cash request was rejected',
							arguments: [],
						},
						causedByMessageId: 'fdb50000-ab05-4ada-28ea-08da441459f5',
					},
					{
						cosmozItemId: '73b5219d-b8a1-4e17-be59-aea7015f4c18',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-06-01T21:19:02.306785Z',
						eventType: 6,
						eventCode: 0,
						eventDescription: {
							text: 'Advance cash request was created',
							arguments: [],
						},
						causedByMessageId: '00160000-ff72-0003-95cf-08da44145968',
					},
				],
			},
			{
				id: '2',
				amount: 1500,
				ledger: {
					id: '1',
					number: 'PC01001',
				},
				number: 'AC10031206',
				currency: 'MXN',
				indicator: 'IB',
				parentPathLocator: '1.271560.1',
				createDate: '2022-06-01T21:19:02.306785Z',
				status: {
					severity: 'ok',
					name: 'approved',
					description: 'Approved',
				},
				events: [
					{
						cosmozItemId: '73b5219d-b8a1-4e17-be59-aea7015f4c18',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-06-09T21:19:02.6461284Z',
						eventType: 8,
						eventCode: 3004,
						eventDescription: {
							text: 'Advance cash request was approved',
							arguments: [],
						},
						causedByMessageId: 'fdb50000-ab05-4ada-28ea-08da441459f5',
					},
					{
						cosmozItemId: '73b5219d-b8a1-4e17-be59-aea7015f4c18',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-06-08T21:19:02.6461284Z',
						eventType: 8,
						eventCode: 3004,
						eventDescription: {
							text: 'Requested amount was updated to {0}',
							arguments: ['1500'],
						},
						causedByMessageId: 'fdb50000-ab05-4ada-28ea-08da441459f5',
					},
					{
						cosmozItemId: '73b5219d-b8a1-4e17-be59-aea7015f4c18',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-06-01T21:19:02.306785Z',
						eventType: 6,
						eventCode: 0,
						eventDescription: {
							text: 'Advance cash request was created',
							arguments: [],
						},
						causedByMessageId: '00160000-ff72-0003-95cf-08da44145968',
					},
				],
			},
			{
				id: '3',
				amount: 800,
				ledger: {
					id: '1',
					number: 'PC01001',
				},
				number: 'AC10020205',
				currency: 'MXN',
				indicator: 'IB',
				parentPathLocator: '1.271560.1',
				createDate: '2022-05-01T21:19:02.306785Z',
				status: {
					severity: 'ok',
					name: 'open',
					description: 'Open',
				},
				events: [
					{
						cosmozItemId: '73b5219d-b8a1-4e17-be59-aea7015f4c18',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-05-01T21:19:02.306785Z',
						eventType: 6,
						eventCode: 0,
						eventDescription: {
							text: 'Advance cash request was created',
							arguments: [],
						},
						causedByMessageId: '00160000-ff72-0003-95cf-08da44145968',
					},
				],
			},
			{
				id: '4',
				amount: 500,
				ledger: {
					id: '3',
					number: 'PC01003',
				},
				number: 'AC20000120',
				currency: 'MXN',
				indicator: 'IB',
				parentPathLocator: '1.271560.1',
				createDate: '2022-05-01T21:19:02.306785Z',
				status: {
					severity: 'ok',
					name: 'open',
					description: 'Open',
				},
				events: [
					{
						cosmozItemId: '73b5219d-b8a1-4e17-be59-aea7015f4c18',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-05-01T21:19:02.306785Z',
						eventType: 6,
						eventCode: 0,
						eventDescription: {
							text: 'Advance cash request was created',
							arguments: [],
						},
						causedByMessageId: '00160000-ff72-0003-95cf-08da44145968',
					},
				],
			},
		],
		ledgers: [
			{
				id: '1',
				number: 'PC01001',
				initialBalance: {
					amount: 20000,
					currency: 'MXN',
				},
				balance: {
					amount: 17797.51,
					currency: 'MXN',
				},
				parentPathLocator: '1.271560.1',
				createDate: '2022-02-01T21:19:02.306785Z',
				status: {
					severity: 'ok',
					name: 'active',
					description: 'Active',
				},
				events: [
					{
						cosmozItemId: '60bef751-1a02-43a6-b3b4-c6d30d6dee06',
						user: {
							id: '215a3794-cc1e-4fed-a72c-aeb600d7ca7b',
							fullName: 'Mattias Berggren (Business Unit Manager)',
						},
						createDate: '2022-06-16T11:04:22.084Z',
						eventDescription: {
							text: _('Reimbursement request {0} was approved. Amount: {1}'),
							arguments: ['RQ213001', 'MXN 702.49'],
						},
					},
				],
			},
			{
				id: '2',
				number: 'PC01002',
				initialBalance: {
					amount: 20000,
					currency: 'MXN',
				},
				balance: {
					amount: 200,
					currency: 'MXN',
				},
				parentPathLocator: '1.271560.1',
				createDate: '2022-02-04T21:19:02.306785Z',
				status: {
					severity: 'warning',
					name: 'inactive',
					description: 'Inactive',
				},
				events: [],
			},
			{
				id: '3',
				number: 'PC01003',
				initialBalance: {
					amount: 50000,
					currency: 'MXN',
				},
				balance: {
					amount: 50000,
					currency: 'MXN',
				},
				parentPathLocator: '1.271560.1',
				createDate: '2022-02-01T21:19:02.306785Z',
				status: {
					severity: 'ok',
					name: 'active',
					description: 'Active',
				},
				events: [],
			},
		],
		reimbursements: [
			{
				id: 'b460fb0f-b251-4217-a77d-ab00ea9b1cf6',
				number: 'RQ213001',
				amount: {
					amount: 702.49,
					currency: 'MXN',
				},
				ledger: {
					id: '1',
					number: 'PC01001',
				},
				indicator: 'IB',
				parentPathLocator: '1.271560.1',
				createDate: '2022-06-16T11:04:22.082Z',
				shadowBatchForUploads: 'c237ed69-c29e-4bc1-af1f-aeca01622919',
				receipts: [
					{
						receipt: {},
						number: '1295450',
						vatAmount: 33.99,
						date: '2022-02-25',
						_rowNumber: 1,
						_index: 0,
						filename: 'SPA810429PU2_RDI841003QJ4_FECC_1295450.xml',
						amount: {
							amount: 237.94,
							currency: 'MXN',
						},
						status: {
							severity: 'ok',
							name: 'validated',
							description: _('Validated'),
						},
					},
					{
						receipt: {},
						number: '1055356',
						vatAmount: 32.37,
						date: '2022-02-16',
						_rowNumber: 2,
						_index: 1,
						filename: 'SPA810429PU2_RDI841003QJ4_FECC_1055356.xml',
						amount: {
							amount: 226.61,
							currency: 'MXN',
						},
						status: {
							severity: 'ok',
							name: 'validated',
							description: _('Validated'),
						},
					},
					{
						receipt: {},
						number: '957952',
						vatAmount: 0,
						date: '2022-02-11',
						_rowNumber: 3,
						_index: 2,
						filename: 'SPA810429PU2_RDI841003QJ4_FECC_957952.xml',
						amount: {
							amount: 237.94,
							currency: 'MXN',
						},
						status: {
							severity: 'ok',
							name: 'validated',
							description: _('Validated'),
						},
					},
				],
				status: {
					severity: 'ok',
					name: 'closed',
					description: 'Closed',
				},
				requirements: [
					{
						description: _('Digitize receipts'),
						fulfilled: true,
						actions: [],
					},
					{
						description: _('Validate receipts'),
						fulfilled: true,
						actions: [],
					},
					{
						description: _('Classify expense'),
						fulfilled: true,
						actions: [],
					},
					{
						description: _('Approve request'),
						fulfilled: true,
						actions: [],
					},
					{
						description: _('Accounting'),
						fulfilled: true,
						actions: [],
					},
					{
						description: _('Approve accounting'),
						fulfilled: true,
						actions: [],
					},
					{
						description: _('Export to SAP'),
						fulfilled: true,
						actions: [],
					},
				],
				events: [
					{
						cosmozItemId: '029314a7-d790-455f-b70b-a96572039c9b',
						user: {
							id: '36d99646-e416-4f5c-a4ea-aeb600d8ce3b',
							fullName: 'Martin Bolfeta (Xpertal Operator)',
						},
						createDate: '2022-06-16T11:06:10.200Z',
						eventDescription: {
							text: 'Reimbursement request was approved',
							arguments: [],
						},
					},
					{
						cosmozItemId: '847fecff-34eb-4bd6-b3fe-3b8116298547',
						user: {
							id: '55555555-5555-5555-5555-555555555555',
							fullName: 'Cosmoz System',
						},
						createDate: '2022-06-16T11:06:10.204Z',
						eventDescription: {
							text: 'Accounting exported to SAP. {0}',
							arguments: [
								{
									itemType: 'DEMOAccountingExport',
									id: 'b460fb0f-b251-4217-a77d-ab00ea9b1cf6',
									humanReadableId: 'Download',
								},
							],
						},
					},
					{
						cosmozItemId: '60bef751-1a02-43a6-b3b4-c6d30d6dee06',
						user: {
							id: '215a3794-cc1e-4fed-a72c-aeb600d7ca7b',
							fullName: 'Mattias Berggren (Business Unit Manager)',
						},
						createDate: '2022-06-16T11:04:22.084Z',
						eventDescription: {
							text: 'Reimbursement request for {0} was created.',
							arguments: ['MXN 702.49'],
						},
					},
				],
			},
		],
		incidents: [
			{
				id: '647fecdf-34eb-4bd6-b3fe-3b8116298547',
				parentPathLocator: '1.271560.1',
				number: 'INC00001',
				reimbursementRequest: {
					id: 'b460fb0f-b251-4217-a77d-ab00ea9b1cf6',
					number: 'RQ213001',
				},
				createDate: '2022-06-16T11:04:22.084Z',
				description: 'Receipt 1295450 does not match',
				status: {
					severity: 'ok',
					name: 'solved',
					description: 'Solved',
				},
				actions: [],
				events: [
					{
						cosmozItemId: '60bef751-1a02-43a6-b3b4-c6d30d6dee06',
						user: {
							id: '36d99646-e416-4f5c-a4ea-aeb600d8ce3b',
							fullName: 'Martin Bolfeta (Xpertal Operator)',
						},
						createDate: '2022-06-18T11:04:22.084Z',
						eventDescription: {
							text: 'Incident was resolved.',
							arguments: [],
						},
					},
					{
						cosmozItemId: '60bef751-1a02-43a6-b3b4-c6d30d6dee06',
						user: {
							id: '215a3794-cc1e-4fed-a72c-aeb600d7ca7b',
							fullName: 'Mattias Berggren (Business Unit Manager)',
						},
						createDate: '2022-06-17T11:04:22.084Z',
						eventDescription: {
							text: 'New file was uploaded.',
							arguments: [],
						},
					},
					{
						cosmozItemId: '60bef751-1a02-43a6-b3b4-c6d30d6dee06',
						user: {
							id: '36d99646-e416-4f5c-a4ea-aeb600d8ce3b',
							fullName: 'Martin Bolfeta (Xpertal Operator)',
						},
						createDate: '2022-06-16T11:04:22.084Z',
						eventDescription: {
							text: 'Incident was created.',
							arguments: [],
						},
					},
				],
			},
		],
	},
	data = demodata() ?? { ...initialData },
	historyEvent = (user, data) => ({
		cosmozItemId: uuidv4(),
		user: {
			id: user?.id ?? '55555555-5555-5555-5555-555555555555',
			fullName: user?.fullName ?? 'Cosmoz System',
		},
		createDate: new Date().toISOString(),
		...data,
	}),
	setACRActions = (item) => {
		return {
			...item,
			actions: [
				{
					name: 'approve',
					title: _('Approve request'),
					description: _('Approve advance cash request'),
					fields: [
						{
							id: 'comment',
							label: _('Comment'),
						},
					],
					requiredFunction: 'PettyCash.ApproveAdvanceCashRequests',
					onSave: (update, item, values, user) => {
						update('acrs', item, {
							...item,
							status: {
								severity: 'ok',
								name: 'approved',
								description: 'Approved',
							},
							events: [
								historyEvent(undefined, {
									eventDescription: {
										text: _('Exported to SAP'),
										arguments: [],
									},
								}),
								historyEvent(user, {
									eventDescription: {
										text: !values.comment
											? _('Advance cash request was approved')
											: _(
													'Advance cash request was approved with comment: {0}',
													'{0}'
											  ),
										arguments: [values.comment],
									},
								}),
								...item.events,
							],
						});

						const ledger = data.ledgers.find((l) => l.id === item.ledger.id);
						update('ledgers', ledger, {
							...ledger,
							balance: {
								...ledger.balance,
								amount: ledger.balance.amount - item.amount,
							},
							events: [
								historyEvent(user, {
									eventDescription: {
										text: _(
											'Advance cash request {0} was approved. Amount: {1}',
											'{0}',
											'{1}'
										),
										arguments: [item.number, item.amount],
									},
								}),
								...ledger.events,
							],
						});
					},
					keep: item.status.name === 'open',
				},
				{
					name: 'reject',
					title: _('Reject request'),
					description: _('Reject advance cash request'),
					requiredFunction: 'PettyCash.ApproveAdvanceCashRequests',
					fields: [
						{
							id: 'reason',
							label: _('Reason'),
							input: autocomplete,
							limit: 1,
							textProperty: 'text',
							validate: required,
							options: [
								{ value: 'cash', text: _('Not enough cash in Petty cash') },
								{ value: 'vouchers', text: _('Not enough vouchers') },
								{ value: 'other', text: _('Other') },
							],
						},
						{
							id: 'comment',
							label: _('Comment'),
							validate: (value, values) => {
								if (values.reason?.value === 'other') {
									return required(value);
								}
							},
						},
					],
					onSave: (update, item, values, user) =>
						update('acrs', item, {
							...item,
							status: {
								severity: 'error',
								name: 'rejected',
								description: 'Rejected',
							},
							events: [
								historyEvent(user, {
									eventDescription: {
										text: _(
											'Advance cash request was rejected with reason: {0}',
											'{0}'
										),
										arguments: [values.reason.text],
									},
								}),
								values.comment &&
									historyEvent(user, {
										eventDescription: {
											text: _('Comment: {0}', '{0}'),
											arguments: [values.comment],
										},
									}),
								...item.events,
							].filter(Boolean),
						}),
					keep: item.status.name === 'open',
				},
				{
					name: 'update',
					title: _('Update amount'),
					description: _('Update requested amount'),
					requiredFunction: 'PettyCash.CreateAdvanceCashRequests',
					fields: [
						{
							id: 'amount',
							label: _('Amount'),
							validate: required,
							input: number,
						},
						{
							id: 'comment',
							label: _('Comment'),
						},
					],
					onSave: (update, item, values, user) =>
						update('acrs', item, {
							...item,
							amount: values.amount,
							status: {
								severity: 'ok',
								name: 'open',
								description: 'Open',
							},
							events: [
								historyEvent(user, {
									eventDescription: {
										text: _(
											'Advance cash request amount was updated to {0} from {1}',
											'{0}',
											'{1}'
										),
										arguments: [values.amount, item.amount],
									},
								}),
								values.comment &&
									historyEvent(user, {
										eventDescription: {
											text: _('Comment: {0}', '{0}'),
											arguments: [values.comment],
										},
									}),
								...item.events,
							].filter(Boolean),
						}),
					keep: item.status.name === 'open' || item.status.name === 'rejected',
				},
				{
					name: 'cancel',
					title: _('Cancel request'),
					description: _('Cancel advance cash request'),
					requiredFunction: 'PettyCash.CreateAdvanceCashRequests',
					fields: [
						{
							id: 'comment',
							label: _('Comment'),
							validate: required,
						},
					],
					onSave: (update, item, values, user) =>
						update('acrs', item, {
							...item,
							status: {
								severity: 'error',
								name: 'cancelled',
								description: 'Cancelled',
							},
							events: [
								historyEvent(user, {
									eventDescription: {
										text: _(
											'Advance cash request was cancelled with comment: {0}',
											'{0}'
										),
										arguments: [values.comment],
									},
								}),
								...item.events,
							],
						}),
					keep: item.status.name === 'open' || item.status.name === 'rejected',
				},
				{
					name: 'comment',
					title: _('Comment'),
					description: _('Add a comment'),
					requiredFunction: 'PettyCash.ListAdvanceCashRequests',
					fields: [
						{
							id: 'comment',
							label: _('Comment'),
							validate: required,
						},
					],
					onSave: (update, item, values, user) =>
						update('acrs', item, {
							...item,
							events: [
								historyEvent(user, {
									eventType: 6,
									eventCode: 0,
									eventDescription: {
										text: _('Comment: {0}', '{0}'),
										arguments: [values.comment],
									},
								}),
								...item.events,
							],
						}),
					keep: true,
				},
			].filter((item) => item.keep),
		};
	},
	setReimbursementActions = (item) => ({
		...item,
		actions: [
			{
				name: 'approve',
				title: _('Approve request'),
				description: _('Approve reimbursement request'),
				fields: [
					{
						id: 'comment',
						label: _('Comment'),
					},
				],
				requiredFunction: 'PettyCash.ApproveReimbursementRequests',
				onSave: (update, item, values, user) => {
					update('reimbursements', item, {
						...item,
						status: {
							severity: 'ok',
							name: 'approved',
							description: 'Approved',
						},
						requirements: [
							...item.requirements.map((r) =>
								r.description === _('Approve request')
									? { ...r, fulfilled: true }
									: r
							),
							{
								description: _('Accounting'),
								fulfilled: false,
								actions: [],
							},
							{
								description: _('Approve accounting'),
								fulfilled: false,
								actions: [],
							},
							{
								description: _('Export to SAP'),
								fulfilled: false,
								actions: [],
							},
						],
						events: [
							historyEvent(user, {
								eventDescription: {
									text: !values.comment
										? _('Reimbursement request was approved')
										: _(
												'Reimbursement request was approved with comment: {0}',
												'{0}'
										  ),
									arguments: [values.comment],
								},
							}),
							...item.events,
						],
					});

					const ledger = data.ledgers.find((l) => l.id === item.ledger.id);
					update('ledgers', ledger, {
						...ledger,
						balance: {
							...ledger.balance,
							amount: ledger.balance.amount - item.amount.amount,
						},
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _(
										'Reimbursement request {0} was approved. Amount: {1}',
										'{0}',
										'{1}'
									),
									arguments: [item.number, item.amount.amount],
								},
							}),
							...ledger.events,
						],
					});
				},
				keep:
					(item.status.name === 'open' || item.status.name === 'validated') &&
					item.requirements.filter((r) => r.fulfilled).length >= 3,
			},
			{
				name: 'classify',
				title: _('Classify expense'),
				description: _('Classify expense'),
				requiredFunction: 'PettyCash.ApproveReimbursementRequests',
				fields: [
					{
						id: 'indicator',
						label: _('Indicator'),
						input: autocomplete,
						limit: 1,
						textProperty: 'text',
						validate: required,
						options: [
							{ value: 'IK', text: _('IK - Non-deductible') },
							{ value: 'IH', text: _('IH - Deductible non-VAT') },
							{ value: 'IB', text: _('IB - Deductible') },
							{ value: 'QA', text: _('QA - Nicaragua') },
							{ value: 'QH', text: _('QH - Panama') },
							{ value: 'QJ', text: _('QJ - Guatemala') },
						],
					},
				],
				onSave: (update, item, values, user) =>
					update('reimbursements', item, {
						...item,
						indicator: values.indicator.text,
						requirements: item.requirements.map((r) =>
							r.description === _('Classify expense')
								? { ...r, fulfilled: true }
								: r
						),
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _('Expense was classified as: {0}', '{0}'),
									arguments: [values.indicator.text],
								},
							}),
							...item.events,
						].filter(Boolean),
					}),
				keep: item.status.name === 'open' || item.status.name === 'validated',
			},
			{
				name: 'reject',
				title: _('Reject request'),
				description: _('Reject reimbursement request'),
				requiredFunction: 'PettyCash.ApproveReimbursementRequests',
				fields: [
					{
						id: 'comment',
						label: _('Comment'),
					},
				],
				onSave: (update, item, values, user) =>
					update('reimbursements', item, {
						...item,
						status: {
							severity: 'error',
							name: 'rejected',
							description: 'Rejected',
						},
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _('Reimbursement request was rejected'),
									arguments: [],
								},
							}),
							values.comment &&
								historyEvent(user, {
									eventType: 6,
									eventCode: 0,
									eventDescription: {
										text: _('Comment'),
										arguments: [],
									},
									comment: values.comment,
								}),
							...item.events,
						].filter(Boolean),
					}),
				keep: item.status.name === 'open' || item.status.name === 'validated',
			},
			{
				name: 'import',
				title: _('Import accounting'),
				description: _('Import accounting'),
				requiredFunction: 'PettyCash.ApproveReimbursementRequests',
				fields: [
					{
						id: 'excel',
						title: _('Accounting file'),
						input: file,
						accept:
							'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					},
				],
				onSave: async (update, item, values, user) => {
					const accountings = await importAccounting(
							values.excel[0],
							importColumns
						),
						accounting = accountings.filter(
							(a) => a.requestNumber === item.number
						);

					if (accounting.length === 0) {
						return;
					}

					update('reimbursements', item, {
						...item,
						accounting: {
							...item.accounting,
							rows: [
								...item.accounting.rows,
								...accounting.map(({ amount, ...dimensions }, index) => ({
									amount,
									dimensions,
									index: index + 1,
									amountCanBeEdited: true,
									canBeDeleted: true,
									editableDimensions: [
										'costCenter',
										'paymentMethod',
										'account',
										'vatCode',
										'division',
										'freeText',
									],
								})),
							],
						},
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _('Accounting was imported'),
									arguments: [],
								},
							}),
							...item.events,
						].filter(Boolean),
					});
				},
				keep: item.status.name === 'approved',
			},
			{
				name: 'openIncident',
				title: _('Open incident'),
				description: _('Open incident'),
				requiredFunction: 'PettyCash.ApproveReimbursementRequests',
				fields: [
					{
						id: 'assignee',
						label: _('Assign'),
						input: autocomplete,
						validate: required,
						textProperty: 'fullName',
						options: assigneeOptions,
						limit: 1,
					},
					{
						id: 'reason',
						label: _('Reason'),
						input: autocomplete,
						limit: 1,
						textProperty: 'text',
						validate: required,
						options: [
							{ value: 'diff', text: _('Difference in amounts') },
							{ value: 'receipts', text: _('Lack of receipts') },
							{ value: 'signatures', text: _('Lack of signatures') },
							{ value: 'tooManyReceipts', text: _('Too many receipts') },
							{
								value: 'deductibles',
								text: _(
									'Change of Expenses from Deductibles to Non-Deductibles'
								),
							},
							{ value: 'blockedCeCo', text: _('Blocked CeCo') },
							{ value: 'accountingError', text: _('Error in accounting data') },
							{ value: 'other', text: _('Other') },
						],
					},
					{
						id: 'otherReason',
						label: _('Your reason'),
						disabled: (value, values) => values.reason?.value !== 'other',
						validate: (value, values) => {
							if (values.reason?.value === 'other') {
								return required(value);
							}
						},
					},
					{
						id: 'comment',
						label: _('Details (optional)'),
					},
				],
				onSave: async (update, item, values, user) => {
					const incident = {
						id: uuidv4(),
						number: 'INC000' + pad(data.incidents.length + 1),
						parentPathLocator: item.parentPathLocator,
						reimbursementRequest: {
							id: item.id,
							number: item.number,
						},
						shadowBatchForUploads: await createShadowBatchForUploads(),
						assignee: values.assignee,
						createDate: new Date().toISOString(),
						description:
							values.reason.value === 'other'
								? values.otherReason
								: values.reason.text,
						status: {
							severity: 'ok',
							name: 'open',
							description: _('Open'),
						},
						actions: [],
						events: [
							values.comment &&
								historyEvent(user, {
									eventType: 6,
									eventCode: 0,
									eventDescription: {
										text: _('Comment'),
										arguments: [],
									},
									comment: values.comment,
								}),
							historyEvent(user, {
								eventDescription: {
									text: 'Incident was created.',
									arguments: [],
								},
							}),
						].filter(Boolean),
					};
					update('reimbursements', item, {
						...item,
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _('Incident {0} filed, with reason: {1}', '{0}', '{1}'),
									arguments: [incident.number, values.reason.text],
								},
							}),
							...item.events,
						].filter(Boolean),
					});
					update('incidents', null, incident);
				},
				keep: item.status.name === 'open' || item.status.name === 'validated',
			},
			{
				name: 'cancel',
				title: _('Cancel request'),
				description: _('Cancel reimbursement request'),
				requiredFunction: 'PettyCash.CreateReimbursementRequests',
				fields: [
					{
						id: 'comment',
						label: _('Comment'),
						validate: required,
					},
				],
				onSave: (update, item, values, user) =>
					update('reimbursements', item, {
						...item,
						status: {
							severity: 'error',
							name: 'cancelled',
							description: 'Cancelled',
						},
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _(
										'Reimbursement request was cancelled with comment: {0}',
										'{0}'
									),
									arguments: [values.comment],
								},
							}),
							...item.events,
						],
					}),
				keep:
					item.status.name === 'open' ||
					item.status.name === 'rejected' ||
					item.status.name === 'validated',
			},
			{
				name: 'reopen',
				title: _('Reopen request'),
				description: _('Reopen reimbursement request'),
				requiredFunction: 'PettyCash.CreateReimbursementRequests',
				fields: [
					{
						id: 'comment',
						label: _('Comment'),
						validate: required,
					},
				],
				onSave: (update, item, values, user) =>
					update('reimbursements', item, {
						...item,
						status: {
							severity: 'ok',
							name: 'open',
							description: 'Open',
						},
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _(
										'Reimbursement request was reopened with comment: {0}',
										'{0}'
									),
									arguments: [values.comment],
								},
							}),
							...item.events,
						],
					}),
				keep: item.status.name === 'rejected',
			},
			{
				name: 'comment',
				title: _('Comment'),
				description: _('Add a comment'),
				requiredFunction: 'PettyCash.ListReimbursementRequests',
				fields: [
					{
						id: 'comment',
						label: _('Comment'),
						validate: required,
					},
				],
				onSave: (update, item, values, user) =>
					update('reimbursements', item, {
						...item,
						events: [
							historyEvent(user, {
								eventDescription: {
									text: _('Comment: {0}', '{0}'),
									arguments: [values.comment],
								},
							}),
							...item.events,
						],
					}),
				keep: true,
			},
		].filter((item) => item.keep),
	}),
	setIncidentsActions = (item) => {
		return {
			...item,
			actions: [
				{
					name: 'solve',
					title: _('Solve incident'),
					description: _('Mark incident as solved'),
					fields: [
						{
							id: 'comment',
							label: _('Comment'),
						},
					],
					requiredFunction: 'PettyCash.ApproveReimbursementRequests',
					onSave: (update, item, values, user) => {
						update('incidents', item, {
							...item,
							status: {
								severity: 'ok',
								name: 'solved',
								description: 'Solved',
							},
							events: [
								values.comment &&
									historyEvent(user, {
										eventType: 6,
										eventCode: 0,
										eventDescription: {
											text: _('Comment'),
											arguments: [],
										},
										comment: values.comment,
									}),
								historyEvent(user, {
									eventDescription: {
										text: _('Incident solved'),
										arguments: [],
									},
								}),
								...item.events,
							].filter(Boolean),
						});

						const request = data.reimbursements.find(
								(l) => l.id === item.reimbursementRequest.id
							),
							receipts = item.receipt
								? request.receipts.map((r) =>
										r.number === item.receipt.number
											? {
													...r,
													status: {
														severity: 'ok',
														name: 'validated',
														description: _('Validated'),
													},
											  }
											: r
								  )
								: request.receipts,
							fulfilled =
								receipts.filter((r) => r.status.name !== 'validated').length ===
								0;
						update('reimbursements', request, {
							...request,
							receipts,
							requirements: request.requirements.map((r) =>
								r.description === _('Validate receipts')
									? { ...r, fulfilled }
									: r
							),
							status:
								request.status.name === 'open' && fulfilled
									? {
											severity: 'ok',
											name: 'validated',
											description: 'Validated',
									  }
									: request.status,
							events: [
								historyEvent(user, {
									eventDescription: {
										text: _('Incident {0} was solved', '{0}'),
										arguments: [item.number],
									},
								}),
								...request.events,
							],
						});
					},
					keep: item.status.name === 'open',
				},
				{
					name: 'comment',
					title: _('Comment'),
					description: _('Add a comment'),
					requiredFunction: 'PettyCash.ListReimbursementRequests',
					fields: [
						{
							id: 'comment',
							label: _('Comment'),
							validate: required,
						},
					],
					onSave: (update, item, values, user) =>
						update('incidents', item, {
							...item,
							events: [
								historyEvent(user, {
									eventType: 6,
									eventCode: 0,
									eventDescription: {
										text: _('Comment'),
										arguments: [],
									},
									comment: values.comment,
								}),
								...item.events,
							],
						}),
					keep: true,
				},
			].filter((item) => item.keep),
		};
	},
	setReceiptsActions = (item) => {
		return {
			...item,
			actions: [
				{
					name: 'validate',
					title: _('Validate'),
					description: _('Validate the receipt'),
					requiredFunction: 'PettyCash.ApproveReimbursementRequests',
					fields: [
						{
							id: 'comment',
							label: _('Comment'),
						},
					],
					onSave: async (
						update,
						item,
						values,
						user,
						{ reimbursementRequest$ }
					) => {
						const reimbursementRequest = await reimbursementRequest$,
							currentReimbursementRequest = data.reimbursements.find(
								(r) => r.id === reimbursementRequest.id
							),
							receipts = currentReimbursementRequest.receipts.map((r) =>
								r.number === item.number
									? {
											...r,
											status: {
												severity: 'ok',
												name: 'validated',
												description: _('Validated'),
											},
									  }
									: r
							),
							fulfilled =
								receipts.filter((r) => r.status.name !== 'validated').length ===
								0;
						update('reimbursements', currentReimbursementRequest, {
							...currentReimbursementRequest,
							requirements: currentReimbursementRequest.requirements.map((r) =>
								r.description === _('Validate receipts')
									? { ...r, fulfilled }
									: r
							),
							status:
								currentReimbursementRequest.status.name === 'open' && fulfilled
									? {
											severity: 'ok',
											name: 'validated',
											description: 'Validated',
									  }
									: currentReimbursementRequest.status,
							receipts,
							events: [
								values.comment &&
									historyEvent(user, {
										eventType: 6,
										eventCode: 0,
										eventDescription: {
											text: _('Comment'),
											arguments: [],
										},
										comment: values.comment,
									}),
								historyEvent(user, {
									eventDescription: {
										text: _('Receipt {0} was validated', '{0}'),
										arguments: [item.number],
									},
								}),
								...currentReimbursementRequest.events,
							].filter(Boolean),
						});
					},
					keep: item.status.name === 'new' || item.status.name === 'review',
				},
				{
					name: 'incident',
					title: _('Raise incident'),
					description: _('Raise incident regarding this receipt'),
					requiredFunction: 'PettyCash.ApproveReimbursementRequests',
					fields: [
						{
							id: 'assignee',
							label: _('Assign'),
							input: autocomplete,
							validate: required,
							textProperty: 'fullName',
							options: assigneeOptions,
							limit: 1,
						},
						{
							id: 'comment',
							label: _('Comment'),
							validate: required,
						},
					],
					onSave: async (
						update,
						item,
						values,
						user,
						{ reimbursementRequest$ }
					) => {
						const reimbursementRequest = await reimbursementRequest$,
							currentReimbursementRequest = data.reimbursements.find(
								(r) => r.id === reimbursementRequest.id
							),
							incident = {
								id: uuidv4(),
								number: 'INC000' + pad(data.incidents.length + 1),
								parentPathLocator:
									currentReimbursementRequest.parentPathLocator,
								reimbursementRequest: {
									id: currentReimbursementRequest.id,
									number: currentReimbursementRequest.number,
								},
								shadowBatchForUploads: await createShadowBatchForUploads(),
								assignee: values.assignee,
								receipt: item,
								createDate: new Date().toISOString(),
								description: values.comment,
								status: {
									severity: 'ok',
									name: 'open',
									description: _('Open'),
								},
								actions: [],
								events: [
									values.comment &&
										historyEvent(user, {
											eventType: 6,
											eventCode: 0,
											eventDescription: {
												text: _('Comment'),
												arguments: [],
											},
											comment: values.comment,
										}),
									historyEvent(user, {
										eventDescription: {
											text: _('Incident was created for receipt {0}.', '{0}'),
											arguments: [item.number],
										},
									}),
								].filter(Boolean),
							};
						update('reimbursements', currentReimbursementRequest, {
							...currentReimbursementRequest,
							receipts: currentReimbursementRequest.receipts.map((r) =>
								r.number === item.number
									? {
											...r,
											incident,
											status: {
												severity: 'warning',
												name: 'review',
												description: _('Under review'),
											},
									  }
									: r
							),
							events: [
								values.comment &&
									historyEvent(user, {
										eventType: 6,
										eventCode: 0,
										eventDescription: {
											text: _('Comment'),
											arguments: [],
										},
										comment: values.comment,
									}),
								historyEvent(user, {
									eventDescription: {
										text: _(
											'Incident {0} raised for receipt {1}',
											'{0}',
											'{1}'
										),
										arguments: [incident.number, item.number],
									},
								}),
								...currentReimbursementRequest.events,
							].filter(Boolean),
						});
						update('incidents', null, incident);
					},
					keep: item.status.name === 'new',
				},
			].filter((item) => item.keep),
		};
	};

data.acrs = data.acrs.map(setACRActions);
data.incidents = data.incidents.map(setIncidentsActions);
data.reimbursements = data.reimbursements.map(setReimbursementActions);

const useUpdateSignal = () => {
		const [signal, setSignal] = useState(0);
		useEffect(() => {
			const handler = () => setSignal((signal) => signal + 1);
			window.addEventListener('petty-cash-data-updated', handler);
			return () =>
				window.removeEventListener('petty-cash-data-updated', handler);
		}, []);
		return signal;
	},
	update = (key, item, newItem) => {
		if (item) {
			data[key] = data[key].map((i) => (i.id !== item.id ? i : newItem));
		} else {
			data[key] = [...data[key], newItem];
		}
		data.acrs = data.acrs.map(setACRActions);
		data.incidents = data.incidents.map(setIncidentsActions);
		data.reimbursements = data.reimbursements.map(setReimbursementActions);
		localStorage.pettycashdemodata = JSON.stringify(data);
		window.dispatchEvent(new CustomEvent('petty-cash-data-updated'));
	},
	reset = () => {
		Object.assign(data, initialData);
		data.acrs = data.acrs.map(setACRActions);
		data.incidents = data.incidents.map(setIncidentsActions);
		data.reimbursements = data.reimbursements.map(setReimbursementActions);
		localStorage.pettycashdemodata = JSON.stringify(data);
		window.dispatchEvent(new CustomEvent('petty-cash-data-updated'));
	},
	hackBoot = (boot) => {
		if (boot.currentUser.userName === 'cristian.ncl@gmail.com') {
			boot.roles[0].functions = boot.roles[0].functions.concat([
				'PettyCash.ListLedgers',
				'PettyCash.CreateLedgers',
				'PettyCash.ListAdvanceCashRequests',
				'PettyCash.CreateAdvanceCashRequests',
				'PettyCash.ApproveAdvanceCashRequests',
				'PettyCash.ListReimbursementRequests',
				'PettyCash.CreateReimbursementRequests',
				'PettyCash.ApproveReimbursementRequests',
			]);
		}
		if (boot.currentUser.userName === 'businessunitmanager') {
			boot.roles[0].functions = boot.roles[0].functions.concat([
				'PettyCash.ListLedgers',
				'PettyCash.ListAdvanceCashRequests',
				'PettyCash.CreateAdvanceCashRequests',
				'PettyCash.ListReimbursementRequests',
				'PettyCash.CreateReimbursementRequests',
			]);
		}
		if (boot.currentUser.userName === 'xpertaloperator') {
			boot.roles[0].functions = boot.roles[0].functions.concat([
				'PettyCash.ListLedgers',
				'PettyCash.CreateLedgers',
				'PettyCash.ListAdvanceCashRequests',
				'PettyCash.ApproveAdvanceCashRequests',
				'PettyCash.ListReimbursementRequests',
				'PettyCash.ApproveReimbursementRequests',
			]);
		}

		return boot;
	};

export {
	data,
	useUpdateSignal,
	update,
	reset,
	hackBoot,
	historyEvent,
	setReceiptsActions,
	setReimbursementActions,
};
